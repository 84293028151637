<template>
  <div class="container">
    <div class="text-right">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        color="#007bff"
        :height="45"
        :width="75"
        :is-full-page="fullPage"
      ></loading>
    </div>
     <v-form>
      <v-row class="text-right">
        <v-col cols="4" class="text-left my-4 pl-5">
          <h2 v-if="getRoleName()!= ''" style="font-weight: 300 !important;font-size:18px">Role: {{getRoleName()}}</h2>
          </v-col>
        <v-col cols="4">
          <v-select
              :items="panel_data"
              label="Select Panel"
              v-model="panel_id"
            ></v-select>
        </v-col>
        <v-col cols="4">
          <v-select
              :items="parent_modules"
              label="Select Parent Module"
              v-model="selected_parent_module"
            ></v-select>
        </v-col>
      </v-row>
     <v-simple-table fixed-header height="480px" style="border:1px solid #ccc" dense>
    <template v-slot:default>
      <thead class="v-data-table-header">

        <tr>
        <th><input type="checkbox" @click="select_all_checkbox($event)"  :checked="checkIfAllTempModulesSelected()"> Select All</th>
        <th>Parent Module</th>
        <th>Module Name</th>
          <th class="text-left" v-for="item in newOperations"
          :key="item.text">
          {{item.text}}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, itemIndex) in modules_temp"
          :key="item.id"
        >
          <td>
            <input type="checkbox" class="foo" :id="item.id" :checked="checkIfAllOperationsSelected(item.id)" @change="selectedCurrentModuleOperations($event, item.id)">
            <!-- <v-checkbox> -->
      </td>
      <td>
        <span v-if="item.parent_id != 0">
            {{item.parent_name}}
          </span>
      </td>
      <td>
        <span>
            {{item.name}}
        </span>
      </td>
      <td v-for="(operation, operationIndex) in newOperations" >
        <input type="checkbox" class="foo"  v-show="checkIfOperationboxShowAble(operation.id, item.id)" :checked="checkIfOperationboxCheckAble(operation.id, item.id)" @click="selectedChild($event, item.id, operation.id)">
    </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
  <v-row
    align="center"
    justify="center" class="text-center mt-2 mb-2"
    v-if="modules_temp.length"
  >
     <v-btn class="mr-4 mt-5" depressed color="primary" @click="submitPermissions"> submit </v-btn>
     <v-btn depressed class=" mr-4 mt-5" color="default" @click="$router.push({ path: './../manage-role' });">
                Cancel
              </v-btn>
  </v-row>

  </v-form>
  </div>
</template>

<script>
import axios from "axios";
import Index from "./Index.vue";
import Buttons from "./component/Buttons.vue";
import Item from "../../components/base/Item.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: { Index, Buttons, Loading },
  data() {
    return {
      //////table data starts here////
      selected: [],
      options: {},
      sort: "",
      total: 0,
      editReportMode: false,
      fromDateMenu: false,
      fromDateVal: null,
      //  minDate: "2020-01-05",
      //  maxDate: "2019-08-30",
      headers: [
        {
          text: "",
          value: "selectallOperations",
          width: "50px",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Modules",
          value: "name",
          width: "200px",
          sortable: false,
          class: "v-data-table-header",
        },
      ],
      ////// table data ends here
            valid: true,
      fullPage: true,
      selection: [],
      module_data: [],
      sectors: [],
      sector_permi: null,
      checked: false,
      modules: [],
      selectedPermission: [],
      operationData: [],
      checked_childId: [],
      checked_parentId: [],
      checkbox: false,
      categories: [],
      panel_data: [],
      selected_parent_module: null,
      parent_modules: [{text:'Select', value:null}],
      checkbox: true,
      query: "",
      categ: "",
      category: "",
      deleteItems: [],
      checkcedstr: "",
      message1: "",
      message2: "",
      upmessage1: "",
      upmessage2: "",
      allSelected: false,
      panel_id:0,
      checked: false,
      //pagination code
      perpage: 50,
      totRecords: null,
      page: 1,
      roles:[],
      isLoading: true,
      fullPage: true,
      newOperations:[],
      raw_modules: []
      ///
    };
  },
  mounted() {
        //this.getResult(this.$route.params.id);
  },
  computed: {
  //pagination computed methods
  modules_temp: function() {
    if(this.panel_id == 0 && this.selected_parent_module == null){
      return this.modules
    }else if(this.panel_id == 0 && this.selected_parent_module != null){
      return this.modules.filter(e => ( e.parent_id == this.selected_parent_module || e.module_id == this.selected_parent_module ))
    }
    else if(this.panel_id != 0 && this.selected_parent_module == null){
      return this.modules.filter(e => e.panel_id == this.panel_id)
    }
    else if(this.panel_id != 0 && this.selected_parent_module != null){
      return this.modules.filter(e => (e.panel_id == this.panel_id && ( e.parent_id == this.selected_parent_module || e.module_id == this.selected_parent_module )))
    }
  }
},
 methods: {
    checkIfInselectedChildren(c, sc) {
      let res = false;
      if (sc.includes(c)) {
        res = true;
      }
      return res;
    },
    checkIfAllTempModulesSelected(){
      let temp_modules = this.modules_temp
      let temp_modules_ids = []
      let letSelectedModules = []
      let result = false
      temp_modules.forEach((temp_module)=>{
        temp_modules_ids.push(temp_module.id)
        if(this.checkIfAllOperationsSelected(temp_module.id)){
          letSelectedModules.push(temp_module.id)
        }
      })
      if(temp_modules_ids.length == letSelectedModules.length){
        result = true
      }
      return result
    },
    select_all_checkbox(e){
      let temp_modules = this.modules_temp
      let temp_modules_ids = []
      temp_modules.forEach((temp_module)=>{
        temp_modules_ids.push(temp_module.id)
        let checkbox = document.getElementById(temp_module.id);
        //console.log(checkbox)
        if (e.target.checked){
            checkbox.checked = true;
          }else{
            checkbox.checked = false;
          }
      })
        let moudles = this.modules;
      let moduleFound = 0;
      if (moudles.length) {
        // have some data
        moudles.forEach((m) => {
          if(temp_modules_ids.includes(m.id)){
          if (m.children.length) {
              // if module have children
              if (e.target.checked) {
                m.selected_children = [];
                m.children.forEach((c)=>{
                  m.selected_children.push(c.id);
                })
                // if (!m.selected_children.includes(childId)) {
                //   m.selected_children.push(childId);
                // }
              } else {
                m.selected_children = [];
              }
            }
        }
        });
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    get_parent_name(parent_id){
      let parent_name = ''
      this.raw_modules.forEach((m)=>{
        if(m.id == parent_id){
          parent_name = m.name
        }
      })
      return parent_name
    },
    reset() {
      this.message1 = "";
      this.upmessage1 = "";
      // this.$refs.form.resetValidation();
      this.$refs.form.reset();
    },
    getResult(role = this.$route.params.id) {
      let data = this;
      const path = data.$url("MODULE");
       data.isLoading = true;
       // panel dropdown
          const panel_data_path = this.$url("PANEL_DATA");
          this.$fetch({
            requiresAuth: true,
            operation: "view",
            vueScope: data
          })
            .get(panel_data_path)
            .then(res => {
              if (res.data.payload.panelId.length) {
                data.panel_data.push({text:'All', value:0});
                res.data.payload.panelId.forEach(function(item) {
                  let a = {
                    text: item[1],
                    value: item[0]
                  };
                  data.panel_data.push(a);
                });
              }
            });
      this.$fetch({ requiresAuth: true, operation : 'view', vueScope: data })
        .get(path)
        .then((res) => {
          data.isLoading = false;
          let module_data;
          module_data = res.data.payload.module.modules_data;
          this.total = module_data.length;
          //console.log( res.data.payload.module)
            let i;
          //data.modules = []
          let temp_modules = []
          data.raw_modules = []
          data.raw_modules = res.data.payload.module.modules_data
            for (i = 0; i < module_data.length; i++) {
              let tempobj = {
                name: module_data[i].module_name,
                id: module_data[i].id,
                children: module_data[i].children,
                parent_id: module_data[i].parent_id,
                selected_children: module_data[i].selected_children,
                mom_id: module_data[i].mom_id,
                roleId: parseInt(this.$route.params.id),
                module_id: module_data[i].module_id,
                panel_id: module_data[i].panel_id,
                // parent_name: data.get_parent_name(module_data[i].parent_id)
                parent_name: module_data[i].parent_name
              };
              temp_modules.push(tempobj);
              //data.modules.push(tempobj);
              if(module_data[i].parent_id == 0 && module_data[i].panel_id == 1){
                data.parent_modules.push({text:module_data[i].module_name, value:module_data[i].id})
              }
            }
          temp_modules.sort((a, b) => a.parent_name.localeCompare(b.parent_name))
          console.log(temp_modules);
          data.modules = []
          data.modules = temp_modules
          //////////////////////////
          let  operation_data = res.data.payload.module.operations;
          if (operation_data.length) {
            let i;
            data.newOperations= [];
            for (i = 0; i < operation_data.length; i++) {
              //console.log(operation_data[i]);
              let a = {
                id: operation_data[i].operation_id,
                text: operation_data[i].operation_name,
                value:  "selectCheckbox" + operation_data[i].operation_id,
                // id: operation_data[i]['operation_id'],
                // text: operation_data[i]['operation_name'],
                // value:  "selectCheckbox" + operation_data[i]['operation_id'],
                sortable: false,
                width: "50px",
                class: "v-data-table-header",
              };
              data.newOperations.push(a);

            }
          }
          const path = this.$url("MODULE_OPERATIONS_DATA") +  "?q=" +
        role;
          this.$fetch({ requiresAuth: true, operation : 'view', vueScope: data })
            .get(path)
            .then((res) => {
              if (res.data.payload.length) {
                console.log(res.data.payload)
                res.data.payload.forEach(function (item) {
                  let a = {
                    role_id: data.$route.params.id,
                    module_id: item.module_id,
                    operation_id: item.operation_id,
                    operation_name: item.operation_name,
                    mom_id: item.mom_id,
                  };
                  data.operationData.push(a);

                });
                this.addPermissionData();
              }
            });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getRoleName(){
      let id  = this.$route.params.id
      let name  = ''
      if(this.roles.length){
        this.roles.forEach((role)=>{
          if(role.value == id){
            name = role.key
          }
        })
      }
      return name
    },
    getRoles(){
      let check = this
      const path = this.$url("USERROLE");
      this.$fetch({
        requiresAuth: true,
        operation: "view",
        vueScope: check,
      })
        .get(path)
        .then((res) => {
          console.log(res);
          this.roleName = res.data.payload.roleId[0];
          if (this.roleName.length) {
            this.roleName.forEach(function (item) {
              let a = {
                key: item[1],
                value: item[0],
              };
              check.roles.push(a);
            });
            check.isRoleDdEditing = true;
            check.role_label = "Select Role"
          }
        });
    },
    checkIfOperationboxShowAble(operationId, mid){
      let result = false;
      let moduleData = this.modules;
      moduleData.forEach((m) =>{
        if(mid == m.id){
          if(m.children.length){
            m.children.forEach((c) => {
              if(c.id==operationId){
                result = true
                if(m.module_id==2){
                }
              }
            })
        }
        }

      }
      )
      return result
    },
    checkIfOperationboxCheckAble(operationId, mid){
      let result = false;
      let moduleData = this.modules;
      moduleData.forEach((m) =>{
        if(mid == m.id){
          if(m.selected_children.length){
            m.selected_children.forEach((c) => {
              if(c==operationId){
                result = "checked"
                if(m.module_id==2){
                }
              }
            })
        }
        }
      }
      )
      return result
    },
    checkIfAllOperationsSelected(moduleId) {
      let moudles = this.modules;
      let result = false
      if (moudles.length) {
        moudles.forEach((m) => {
          if (m.id === moduleId) {
            if (m.children.length == m.selected_children.length) {
              result = true
            }
          }
        });
      }
      return result
    },
    selectedCurrentModuleOperations(e, moduleId) {
      let moudles = this.modules;
      let moduleFound = 0;
      if (moudles.length) {
        // have some data
        moudles.forEach((m) => {
          if (m.id === moduleId) {
            if (m.children.length) {
              // if module have children
              if (e.target.checked) {
                m.selected_children = [];
                m.children.forEach((c)=>{
                  m.selected_children.push(c.id);
                })
                // if (!m.selected_children.includes(childId)) {
                //   m.selected_children.push(childId);
                // }
              } else {
                m.selected_children = [];
              }
            }
          }
        });
      }
      //console.log(selected);
    },
    selectedChild(e, moduleId, childId) {
      let selected = this.modules;
      let moduleFound = 0;
      if (selected.length) {
        // have some data
        selected.forEach((m) => {
          if (m.id === moduleId) {
            if (m.selected_children.length) {
              // if module have children
              if (e.target.checked) {
                if (!m.selected_children.includes(childId)) {
                  m.selected_children.push(childId);
                }
              } else {
                m.selected_children.splice(
                  m.selected_children.indexOf(childId),
                  1
                );
              }
            } else {
              // no children
              if (e.target.checked) {
                m.selected_children.push(childId);
              }
            }
            moduleFound = moduleFound + 1;
          }
        });
        if (moduleFound == 0) {
          let module = {
            id: moduleId,
            selected_children: [childId],
          };
          selected.push(module);
        }
      } else {
        //no data
        let module = {
          id: moduleId,
          selected_children: [childId],
        };
        selected.push(module);
      }
      console.log(selected);
    },
    addPermissionData(e) {
      let data = this;
      let modules = data.modules;
      let permissionData = data.operationData;
      if (permissionData.length) {
        modules.forEach((m) => {
          permissionData.forEach((p) => {
            if (m.id == p.module_id) {
              if (!m.selected_children.includes(p.operation_id)) {
                m.selected_children.push(p.operation_id);
                m.mom_id.push(p.mom_id);
                m.module_id = p.module_id;
              }
            }
          });
        });
      }
    },
    selectedChild(e, moduleId, childId) {
      let selected = this.modules;
      let moduleFound = 0;
      if (selected.length) {
        // have some data
        selected.forEach((m) => {
          if (m.id === moduleId) {
            if (m.selected_children.length) {
              // if module have children
              if (e.target.checked) {
                if (!m.selected_children.includes(childId)) {
                  m.selected_children.push(childId);
                }
              } else {
                m.selected_children.splice(
                  m.selected_children.indexOf(childId),
                  1
                );
              }
            } else {
              // no children
              if (e.target.checked) {
                m.selected_children.push(childId);
              }
            }
            moduleFound = moduleFound + 1;
          }
        });
        if (moduleFound == 0) {
          let module = {
            id: moduleId,
            selected_children: [childId],
          };
          selected.push(module);
        }
      } else {
        //no data
        let module = {
          id: moduleId,
          selected_children: [childId],
        };
        selected.push(module);
      }
      console.log(selected);
    },
    submitPermissions(e) {
      e.preventDefault();
      let data = this;
      data.$swal
            .fire({
              title: "Are you sure ?",
              text:
                "This will update new module operations permissions",
              type: "warning",
              showCancelButton: true,
              confirmButtonColor: "#DD6B55",
              confirmButtonText: "Yes, Submit!",
              cancelButtonText: "No, cancel !",
              closeOnConfirm: false,
              closeOnCancel: false,
            })
            .then((result) => {
              if (result.isConfirmed) {
                data.isLoading = true;
                data.$fetch({ requiresAuth: true, operation : 'edit', vueScope: data })
                .put(data.$url("MODULE_OPERATIONS_DATA"), data.modules)
                .then((result) => {
                  data.isLoading = false;
                  if (result.data.statusCode == 200) {
                    this.$swal.fire({
                      icon: "success",
                      title: "Permission Updated",
                      text: result.data.payload.message,
                    });
                  }
                  console.log(result);
                }).catch((error) => {
                  data.isLoading = false;
                  console.error(error);
                });

              } else if (result.isDenied) {
                data.$swal.fire(
                  "Cancelled",
                  "File was not deleted!",
                  "error"
                );
              }
            });
    },
    selectAll: function () {
      let delaffinity = this.modules;
      if (this.allSelected == true) {
        if (Array.isArray(delaffinity) && delaffinity.length) {
          this.deleteItems = [];
          let i;
          for (i = 0; i < delaffinity.length; i++) {

              this.deleteItems.push(delaffinity[i][0]);
              console.log("gsdhghshsjss");

          }
        }
      } else if (this.allSelected == false) {
        this.deleteItems = [];
      }
    },
    select: function () {
      this.allSelected = false;
    },
    submit() {
      this.$v.$touch();
    },
  },
  watch: {
    '$store.state.Rawdata.current_active_module': function(o) {
      if(o){
        this.getResult(this.$route.params.id);
        this.getRoles();
      }
    },
 },
};
</script>
<style  scoped>
/*
 Only custom marker for summary/details
 For cross browser compatible styling hide Firefox's marker by setting summary { display: block }
 and Chrome and Safari's marker by setting ::-webkit-details-marker {display: none;}
*/
summary {
  display: block;
  cursor: pointer;
  outline: 0;
}

summary::-webkit-details-marker {
  display: none;
}

.tree-nav__item {
  display: block;
  white-space: nowrap;
  color: #ccc;
  position: relative;
}
.tree-nav__item.is-expandable::before {
  content: "";
  height: 100%;
  left: 0.8rem;
  position: absolute;
  top: 2.4rem;
  height: calc(100% - 2.4rem);
}
.tree-nav__item .tree-nav__item {
  margin-left: 2.4rem;
}
.tree-nav__item.is-expandable[open] > .tree-nav__item-title::before {
  font-family: "ionicons";
  transform: rotate(180deg);
  content: "-";
}
.tree-nav__item.is-expandable > .tree-nav__item-title {
  padding-left: 2.4rem;
}
.tree-nav__item.is-expandable > .tree-nav__item-title::before {
  position: absolute;
  will-change: transform;
  transition: transform 300ms ease;
  font-family: "ionicons";
  color: #000;
  font-size: 1.1rem;
  content: "+";
  left: 0;
  display: inline-block;
  width: 1.6rem;
  text-align: center;
}

.tree-nav__item-title {
  cursor: pointer;
  display: block;
  outline: 0;
  color: #000;
  font-size: 1rem;
  line-height: 3.2rem;
}
.tree-nav__item-title .icon {
  display: inline;
  padding-left: 1.6rem;
  margin-right: 0.8rem;
  color: #666;
  font-size: 1.4rem;
  position: relative;
}
.tree-nav__item-title .icon::before {
  top: 0;
  position: absolute;
  left: 0;
  display: inline-block;
  width: 1.6rem;
  text-align: center;
}

.tree-nav__item-title::-webkit-details-marker {
  display: none;
}
.theme--light.v-data-table.v-data-table--fixed-header thead th {
    background: #3f51b5!important;
}
</style>
